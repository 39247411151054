<template>
  <input type="text" v-model="searchTerm" @keyup.enter="() => getDocuments(false)" class="search-bar" placeholder="Search for Clients (using Name or Tel.)">
  <table>
    <thead>
      <th>Name</th>
      <th>Phone</th>
      <th>Email</th>
      <th>Address</th>
      <th></th>
    </thead>
    <tbody v-if="clients">
      <TableRow mode="create" />
      <TableRow v-for="client in clients" :key="client.id" :client="client" />
    </tbody>
  </table>
  <div v-if="isLoading" class="loading">Fetching clients... ⏳</div>
  <div v-if="!isLoading && clients && clients.length && isEmpty" class="success">That's all for now 👍</div>
  <div v-if="!isLoading && !error && (!clients || !clients.length)" class="error">No Clients match selected criteria 🤷</div>
  <div v-if="!isLoading && error" class="error">{{ error }}</div>
  <div v-if="clients && clients.length && !isEmpty" class="load-more">
    <button @click="() => getDocuments(true)">Load More 👇</button>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import useFirestore from '@/composables/useFirestore'
import TableRow from './TableRow.vue'

export default {
  components: { TableRow },
  setup() {
    const router = useRouter()
    const { documents: clients, isEmpty, error, isLoading, listenDocuments } = useFirestore()
    const searchTerm = ref(null)

    const getDocuments = (attach) => {
      const searchField = (/^\d+$/.test(searchTerm.value)) ? 'tel' : 'name'

      listenDocuments(
        'clients', 
        null, 
        null, 
        attach, 
        searchTerm.value ? null : ['createdAt', 'desc'], 
        !searchTerm.value ? null : [searchField, '>=', `${searchTerm.value.charAt(0).toUpperCase()}${searchTerm.value.slice(1)}`], 
        !searchTerm.value ? null : [searchField, '<=', `${searchTerm.value.charAt(0).toUpperCase()}${searchTerm.value.slice(1)}`+ '\uf8ff']
      )
    }

    getDocuments()

    return { router, clients, isEmpty, error, isLoading, searchTerm, getDocuments }
  }
}
</script>