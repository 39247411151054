<template>
  <div class="modal show">
    <div v-if="!showMergeModal" class="inner-modal">
      <div v-if="mode !== 'edit'" class="title">Add New Client 🧑‍🦱</div>    
      <div v-else class="title">Edit Client 🧑‍🦱</div> 
      <form @submit.prevent="handleSave">
        <label>Client Name:</label>
        <input type="text" placeholder="e.g. John Smith" v-model="client.name">
        <label>Client Email:</label>
        <input type="email" placeholder="e.g. johns@example.com" v-model="client.email">
        <label>Client Telephone Number:</label>
        <input type="text" placeholder="e.g. 07777777777" v-model="client.tel">
        <label>Client Address:</label>
        <input type="text" placeholder="e.g. 1 Your Street, X14 1YY" v-model="client.address">
        <div class="buttons">
          <button v-if="!isLoading" type="submit" class="swal2-styled swal2-confirm">Save</button>
          <button v-if="!isLoading && mode === 'edit'" type="button" class="swal2-styled swal2-deny" @click="showMergeModal = true">Merge</button>
          <button v-if="!isLoading" type="button" class="swal2-styled swal2-cancel" @click="handleClose">Close</button>
          <button v-else class="swal2-styled swal2-confirm" disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
        </div>
      </form>
    </div>
    <MergeModal v-if="client && showMergeModal" :client="{ id: client.id, name: client.name, tel: client.tel }" @modal-close="showMergeModal = false" />
  </div>
</template>

<script>
import { ref } from 'vue'
import useFetch from '@/composables/useFetch'
import MergeModal from './MergeModal.vue'
import { Toast } from '@/composables/useSwal'

export default {
  props: ['mode', 'client'],
  components: { MergeModal },
  emits: ['modal-close'],
  setup(props, { emit }) {
    const { error, isLoading, fetchData } = useFetch()
    const showMergeModal = ref(false)
    const client = (props.mode === 'edit' && props.client) 
      ? ref(props.client)
      : ref({
        name: null,
        email: null,
        tel: null,
        address: null
      })
    
    const handleSave = async () => {
      if (props.mode !== 'edit') {
        await fetchData('/clients', 'post', client.value)
      } else {
        await fetchData(`/clients/${client.value.id}`, 'patch', client.value)
      }

      if (!error.value) {
        emit('modal-close')

        Toast.fire({
          icon: 'success',
          title: 'Success!',
          text: props.mode !== 'edit' ? 'Client added successfully' : 'Client updated successfully'
        })
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Uh-oh...',
          text: `Error: ${error.value}`
        })
      }
    }

    const handleClose = () => {
      emit('modal-close')
    }

    return { isLoading, client, showMergeModal, handleSave, handleClose }
  }
}
</script>