<template>
  <tr v-if="mode === 'create' || mode === 'edit'">
    <td><input type="text" placeholder="e.g. John Smith" v-model="client.name"></td>
    <td><input type="text" placeholder="e.g. 07777777777" v-model="client.tel"></td>
    <td><input type="email" placeholder="e.g. johns@example.com" v-model="client.email"></td>
    <td><input type="text" placeholder="e.g. 1 Your Street, X14 1YY" v-model="client.address"></td>
    <td class="button-area">
      <button v-if="!isLoading && mode === 'edit'" @click="handleSave">Update</button>
      <button v-if="!isLoading && mode === 'edit'" @click="mode = null" class="cancel">Cancel</button>
      <button v-if="!isLoading && mode === 'create'" class="secondary" @click="handleSave">Add</button>
      <button v-else-if="isLoading && mode === 'edit'" disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
      <button v-else-if="isLoading && mode === 'create'" class="secondary" disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
    </td>
  </tr>
  <tr v-else @click="handleRoute">
    <td :class="{ 'tilted': !client.name }">{{ client.name || 'Unknown' }}</td>
    <td :class="{ 'tilted': !client.tel }">{{ client.tel || 'Unknown' }}</td>
    <td :class="{ 'tilted': !client.email }">{{ client.email || 'Unknown' }}</td>
    <td :class="{ 'tilted': !client.address }">{{ client.address || 'Unknown' }}</td>
    <td class="button-area">
      <button v-if="!isLoading && !mode" @click="mode = 'edit'">Edit</button>
    </td>
  </tr>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import useFetch from '@/composables/useFetch'
import { Toast } from '@/composables/useSwal'

export default {
  props: ['mode', 'client'],
  setup(props) {
    const router = useRouter()
    const { error, isLoading, fetchData } = useFetch()
    const mode = ref(props.mode)
    const client = ((!props.mode || props.mode === 'edit') && props.client) 
      ? ref(props.client)
      : ref({
          name: null,
          email: null,
          tel: null,
          address: null
        })

    const handleRoute = (e) => {
      if (mode.value == 'edit' || !client.value.id || e.target.tagName === 'A') {
        return
      }

      router.push({ name: 'Client', params: { id: client.value.id } })
    }

    const handleSave = async () => {
      if (mode.value !== 'edit') {
        await fetchData('/clients', 'post', client.value)
      } else {
        await fetchData(`/clients/${client.value.id}`, 'patch', client.value)
      }

      if (!error.value) {
        Toast.fire({
          icon: 'success',
          title: 'Success!',
          text: mode.value !== 'edit' ? 'Client added successfully' : 'Client updated successfully'
        })

        if (mode.value === 'edit') {
          mode.value = null
        } else if (mode.value === 'create') {
          client.value = {
            name: null,
            email: null,
            tel: null,
            address: null
          }
        }
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Uh-oh...',
          text: `Error: ${error.value}`
        })
      }
    }

    return { isLoading, mode, client, handleRoute, handleSave }
  }
}
</script>