<template>
  <div class="page-header">
    <h3>All Clients</h3>
    <button @click="showClientModal = true">New Client</button>
  </div>
  <div class="container">
    <div class="inner-container">
      <div class="page-subtitle">List of Clients 🧑‍🦱</div>
      <ClientTable />
    </div>
  </div>
  <ClientModal v-if="showClientModal" @modal-close="showClientModal = false" />
</template>

<script>
import { ref } from 'vue'
import ClientTable from '@/components/Client/Table.vue'
import ClientModal from '@/components/Client/Modal.vue'

export default {
  components: { ClientTable, ClientModal },
  setup() {
    const showClientModal = ref(false)

    return { showClientModal }
  }
}
</script>

<style>

</style>