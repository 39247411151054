<template>
  <div class="select-client" tabindex="0" @focus="showClientList = true" @focusout="showClientList = false">
    <div class="search-wrap">
      <input type="text" :value="searchTerm" @input="handleSearchTerm" @change="handleSearchChange" @keydown.enter.prevent="handleSearch" @focus="showClientList = true" placeholder="Search for Client">
      <div :class="{ shown: showClientList }" class="clients-list">
        <div v-if="!isLoading">
          <div v-for="client in clients" :key="client.id" @click="() => handleClient(client)" class="client-item text">
            {{ client.name }} - (Tel: {{ client.tel || 'Unknown' }})
          </div>
        </div>
        <div v-if="!isLoading && clients !== null && !clients.length" class="text">No Clients match searched term</div>
        <div v-if="!isLoading && clients === null" class="text">Search for Client using their Name or Phone No.</div>
        <div v-if="!isLoading && error" class="text">{{ error }}</div>
        <div v-if="isLoading" class="text">Searching Clients...</div>
      </div>
      <div v-if="selectedClient && selectedClient.id && mode !== 'queue'" @click="handleRemove" class="chosen-client">{{ selectedClient.name }}</div>
    </div>
    <button v-if="mode !== 'queue'" type="button" @click="handleAdd">+</button>
  </div>
</template>

<script>
import { ref } from 'vue'
import useFirestore from '@/composables/useFirestore'

export default {
  props: ['mode', 'clientId', 'initValue', 'selectedClient'],
  emits: ['selected-client', 'add-client', 'open-client-modal'],
  setup(props, { emit }) {
    const { error, isLoading, getDocuments } = useFirestore()
    const clients = ref(null)
    const showClientList = ref(false)
    const searchTerm = ref(props.initValue || null)

    const handleSearchTerm = (e) => {
      searchTerm.value = e.target.value
    }

    const handleSearchChange = (_, clearTel) => {
      let searchField = (/^\d+$/.test(searchTerm.value)) ? 'tel' : 'name'
      emit('add-client', searchField === 'name' ? searchTerm.value || null : null, clearTel)
    }

    const handleSearch = async () => {
      if (searchTerm.value) {
        let searchField = (/^\d+$/.test(searchTerm.value)) ? 'tel' : 'name'

        const clientsRes = await getDocuments(
          'clients',
          [searchField, '>=', `${searchTerm.value.charAt(0).toUpperCase()}${searchTerm.value.slice(1)}`],
          [searchField, '<=', `${searchTerm.value.charAt(0).toUpperCase()}${searchTerm.value.slice(1)}`+ '\uf8ff']
        )

        clients.value = props.clientId ? clientsRes.filter(el => el.id !== props.clientId) : clientsRes
        
        if (clients.value && clients.value.length) {
          showClientList.value = true
        } else {
          handleSearchChange(null, true)
        }
      } else {
        clients.value = null
      }
    }
    
    const handleClient = (client) => {
      emit('selected-client', {
        id: client.id,
        name: client.name,
        tel: client.tel
      })

      showClientList.value = false
      searchTerm.value = client.name
    }
    
    const handleRemove = () => {
      emit('selected-client', null)
    }

    const handleAdd = () => {
      emit('open-client-modal')
    }

    return { clients, showClientList, searchTerm, error, isLoading, handleSearchTerm,
      handleSearchChange, handleSearch, handleClient, handleRemove, handleAdd }
  }
}
</script>