<template>
  <div class="inner-modal">
    <div class="title">Merge Client Into</div>
    <form @submit.prevent="handleMerge">
      <label>Client:</label>
      <div v-if="clientError" class="error">Please pick a client</div>
      <InputSearch :mode="'edit'" :clientId="client.id" :selectedClient="clientToMergeInto" @selected-client="handleClient" />
      <div class="buttons">
        <button v-if="!isLoading" type="submit" class="swal2-styled swal2-confirm">Merge</button>
        <button v-if="!isLoading" type="button" class="swal2-styled swal2-cancel" @click="handleClose">Close</button>
        <button v-else class="swal2-styled swal2-confirm" disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import useFetch from '@/composables/useFetch'
import { Swal, Toast } from '@/composables/useSwal'
import InputSearch from './InputSearch.vue'

export default {
  props: ['client'],
  emits: ['modal-close'],
  components: { InputSearch },
  setup(props, { emit }) {
    const router = useRouter()
    const { error, isLoading, fetchData } = useFetch()
    const clientToMergeInto = ref(null)
    const clientError = ref(false)
    
    const handleClient = (client) => {
      clientToMergeInto.value = client
    }

    const handleMerge = async () => {
      const popup = await Swal.fire({
        title: 'Uh-oh...',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Merge Client`,
        html: 
        `<h4>The merge will mean ${props.client.name}\'s Bookings and Invoices will now be associated with ${clientToMergeInto.value.name}.</h4><br>
        <h4>This action is irrevirsable.</h4><br>
        <h4>Do you confirm?</h4></div>`
      })

      if (popup.isConfirmed) {
        await fetchData(`/clients/${props.client.id}/merge`, 'post', { 
          newClientId: clientToMergeInto.value.id 
        })

        if (!error.value) {
          Toast.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Clients merged successfully'
          })

          router.push({ name: 'Clients' })
        } else {
          Toast.fire({
            icon: 'error',
            title: 'Oops...',
            text: `Error: ${error.value}`
          })
        }
      }
    }
    
    const handleClose = () => {
      emit('modal-close')
    }

    return { clientToMergeInto, clientError, error, isLoading, handleClient, 
      handleMerge, handleClose }
  }
}
</script>